// Function to convert IRentIncreaseTableForm to RentReviewTableValue
import type {
  IRentIncreaseTableForm,
  RentReviewTableValue as RentReviewTableValueTable,
} from '@register/components/Review/Form/RentReviewTable'
import type { RentReviewTable } from '@manager/components/Group/Custom/RentReviewTable/types'
import type { OptionRentReviewTable } from '@manager/components/Group/Custom/OptionRentIncreaseTable/types'
import { IncreaseTableMethod } from '@register'

const toNumber = (val: string | null | undefined) =>
  typeof val === 'string' ? Number(val) : null
const toString = (val: number | null | undefined) =>
  typeof val === 'number' ? String(val) : undefined

export function convertToRentReviewAPI(
  form: IRentIncreaseTableForm,
): RentReviewTable {
  const data: RentReviewTable = {
    date: form.date,
    method: convertMethodToAPI(form.method),
    fixedPercent: toNumber(form.fixedPercent),
    fixedAmount: toNumber(form.fixedAmount),
    cpiPercent: toNumber(form.cpiPercent),
    cpiCap: toNumber(form.cpiCap),
    cpiCollar: toNumber(form.cpiCollar),
    marketReviewDate: form.marketReviewDate,
    marketReviewCap: toNumber(form.marketReviewCap),
    marketReviewCollar: toNumber(form.marketReviewCollar),
    marketReviewAmount: toNumber(form.marketReviewAmount),
    greaterOf: toNumber(form.greaterOf),
    lesserOf: toNumber(form.lesserOf),
    formula: form.formula,
  } as RentReviewTable

  Object.defineProperty(data, '__duplicate', {
    enumerable: false,
    value: form.duplicate,
  })

  return data
}

// Function to convert RentReviewTableValue to IRentIncreaseTableForm
export function convertToIRentIncreaseTableForm(
  value: RentReviewTable,
): IRentIncreaseTableForm {
  return {
    date: value.date ?? undefined,
    method: convertMethodToTable(value.method),
    fixedPercent: toString(value.fixedPercent),
    fixedAmount: toString(value.fixedAmount) ?? undefined,
    cpiPercent: toString(value.cpiPercent) ?? undefined,
    cpiCap: toString(value.cpiCap),
    cpiCollar: toString(value.cpiCollar),
    marketReviewDate: value.marketReviewDate ?? undefined,
    marketReviewCap: toString(value.marketReviewCap),
    marketReviewCollar: toString(value.marketReviewCollar),
    marketReviewAmount: toString(value.marketReviewAmount),
    greaterOf: toString(value.greaterOf),
    lesserOf: toString(value.lesserOf),
    formula: value.formula ?? undefined,
    duplicate: value.__duplicate,
  }
}

export function convertToRentReviewTable(
  value: RentReviewTable | OptionRentReviewTable,
): RentReviewTableValueTable {
  const data = {
    option: (value.option as string | number | null) ?? undefined,
    date: value.date ?? undefined,
    method: convertMethodToTable(value.method),
    fixedPercent: toString(value.fixedPercent),
    fixedAmount: toString(value.fixedAmount),
    cpiPercent: toString(value.cpiPercent),
    cpiCap: toString(value.cpiCap),
    cpiCollar: toString(value.cpiCollar),
    marketReviewDate: value.marketReviewDate ?? undefined,
    marketReviewCap: toString(value.marketReviewCap),
    marketReviewCollar: toString(value.marketReviewCollar),
    marketReviewAmount: toString(value.marketReviewAmount),
    greaterOf: toString(value.greaterOf),
    lesserOf: toString(value.lesserOf),
    formula: value.formula ?? undefined,
    value: (value.value as string | null) ?? undefined,
    rent: (value.rent as number | null) ?? undefined,
    duplicate: value.__duplicate as boolean | undefined,
    status: value.__status as 'created' | 'edited' | 'generated' | undefined,
    evaluatedAt: (value.__evaluatedAt as string | undefined) ?? undefined,
  }

  Object.defineProperty(data, '__id', {
    enumerable: false,
    value: value.__id,
  })

  return data
}

export function convertMethodToAPI(
  method: IncreaseTableMethod | null | undefined,
) {
  switch (method) {
    case IncreaseTableMethod.FIXED_AMOUNT:
      return 'FixAmount'
    case IncreaseTableMethod.FIXED_PERCENT:
      return 'FixPercentage'
    case IncreaseTableMethod.CPI:
      return 'Cpi'
    case IncreaseTableMethod.CPI_PLUS:
      return 'CpiPlus'
    case IncreaseTableMethod.MARKET:
      return 'Market'
    default:
      return null
  }
}
export function convertMethodToTable(method: string | null | undefined) {
  switch (method) {
    case 'FixAmount':
      return IncreaseTableMethod.FIXED_AMOUNT
    case 'FixPercentage':
      return IncreaseTableMethod.FIXED_PERCENT
    case 'Cpi':
      return IncreaseTableMethod.CPI
    case 'CpiPlus':
      return IncreaseTableMethod.CPI_PLUS
    case 'Market':
      return IncreaseTableMethod.MARKET
    default:
      return undefined
  }
}
